import { render, staticRenderFns } from "./list.vue?vue&type=template&id=25345fa2&scoped=true"
import script from "./list.vue?vue&type=script&lang=js"
export * from "./list.vue?vue&type=script&lang=js"
import style0 from "./list.vue?vue&type=style&index=0&id=25345fa2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25345fa2",
  null
  
)

export default component.exports